<template>
  <svg
    id="Layer_1"
    :width="width"
    :height="height"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m205.2 129c-10.6 0-20.1 6.6-23.8 16.5l-76.2 203.2c-4.9 13.1 1.7 27.8 14.9 32.7 13.1 4.9 27.8-1.7 32.7-14.9l12.9-34.3h79.1l12.9 34.3c3.8 10.2 11.8 23 32.7 14.9 13.1-5.1 19.8-19.6 14.9-32.7l-76.3-203.2c-3.7-9.9-13.2-16.5-23.8-16.5zm-20.5 152.4 20.5-54.7 20.5 54.7z" :fill="iconColor"/>
      <path d="m281.4 52.8h-254c-14 0-25.4 11.4-25.4 25.4v355.6c0 14 11.4 25.4 25.4 25.4h254c14 0 25.4-11.4 25.4-25.4s-11.4-25.4-25.4-25.4h-228.6v-304.8h228.6c14 0 25.4-11.4 25.4-25.4s-11.4-25.4-25.4-25.4z" :fill="iconColor"/>
      <path d="m484.6 459.2h-25.4v-406.4h25.4c14 0 25.4-11.4 25.4-25.4s-11.4-25.4-25.4-25.4h-101.6c-14 0-25.4 11.4-25.4 25.4s11.4 25.4 25.4 25.4h25.4v406.4h-25.4c-14 0-25.4 11.4-25.4 25.4s11.4 25.4 25.4 25.4h101.6c14 0 25.4-11.4 25.4-25.4s-11.4-25.4-25.4-25.4z" :fill="iconColor"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconTextEditor",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
